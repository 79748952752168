import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import './UserGuide.css';

function UserGuide(props) {
  const { toggleDisplayGuide, createNewItem } = props;
  return (
    <div className="sf-app-userguide">
      <div className="sf-app-userguide-jumbo">
        <div><input className="sf-app-userguide-closex" type="button" onClick={toggleDisplayGuide} value="X" alt="Close" /></div>
        <h6>What is it?</h6>
        <p>
            Do you find you are often typing out the same text every day? Maybe its your email address, a phone number or some a standard email response. Whatever it is why not &apos;save your fingers&apos; and instead save it once in here and then its always available to one-click copy it to your clipboard for pasting wherever you need.
        </p>
        <h6>How does it work?</h6>
        <p>
            Click &quot;Create New Item&quot; to enter the common text that you want to copy/paste and give it a friendly name. The item will appear in the list whenever you visit this site on this browser.
            When you need to use the text somewhere just click the &quot;Copy&quot; button the item and the text will be sent to your clipboard ready for you to paste wherever you need it.
            Copy a lot of items everyday? Why not leave this page open in your browser and just flip to it when you need that text in your clipboard.
        </p>
        <h6>Where is my data stored?</h6>
        <p>
            All your data is stored in this browser on your machine and nothing is sent to our servers so your privacy is respected. To backup your data items you can choose &quot;Export Items&quot; and then save the data to a file.
        </p>
        <h6>How do I get started?</h6>
        <p>
            Just click the &quot;Create New Item&quot; button to add your first entry. We have added some example ones that you can delete at your leisure.
        </p>
        <p>
          <Button className="sf-app-userguide-commands" variant="primary" size="sm" onClick={createNewItem}>Create New Item</Button>
          <Button className="sf-app-userguide-commands" variant="secondary" size="sm" onClick={toggleDisplayGuide}>Hide this guide</Button>
        </p>
      </div>
    </div>
  );
}

UserGuide.propTypes = {
  toggleDisplayGuide: PropTypes.func.isRequired,
  createNewItem: PropTypes.func.isRequired,
};

export default UserGuide;
