import { v4 as uuidv4 } from 'uuid';

const CommonFunctions = {
  GenerateRandomNumber() {
    return `${Math.floor(Math.random() * Math.floor(1000))}`;
  },
  GenerateUniqueID() {
    return uuidv4(); // e.g. '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
  },
};

export default CommonFunctions;
