/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import ItemComponent from './ItemComponent';
import Logger from './Logger';

function ItemsContainer(props) {
  const { items, editItem, deleteItem } = props;
  const itemsList = items;
  Logger.LogDebug('ItemsContainer render');
  return (
    <Container fluid>
      <Row>
        <Col lg={true} className="sf-app-container-col">
          {itemsList.map((item) => (
            <ItemComponent
              key={item.id}
              item={item}
              editItem={editItem}
              deleteItem={deleteItem}
            />
          ))}
        </Col>
      </Row>
    </Container>
  );
}

ItemsContainer.propTypes = {
  items: PropTypes.array.isRequired,
  editItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

export default ItemsContainer;
