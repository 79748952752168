import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';

function ConfirmActionModal(props) {
  const {
    title,
    modalOpen,
    message,
    cancelButtonText,
    okButtonText,
    proceedAction,
    cancelAction,
  } = props;

  const handleCancel = () => cancelAction();
  const handleOk = () => proceedAction();

  return (
    <>
      <Modal show={modalOpen} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            {cancelButtonText}
          </Button>
          <Button variant="primary" onClick={handleOk}>
            {okButtonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ConfirmActionModal.defaultProps = {
  title: 'Confirm action',
  message: 'Proceed?',
  cancelButtonText: 'Cancel',
  okButtonText: 'Ok',
  modalOpen: false,
};

ConfirmActionModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  cancelButtonText: PropTypes.string,
  okButtonText: PropTypes.string,
  proceedAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool,
};

export default ConfirmActionModal;
