/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import './NavigationBar.css';
import Logger from './Logger';

function NavigationBar(props) {
  const { onNewItemClick, onDisplayDataEditor, onToggleDisplayGuide } = props;
  Logger.LogDebug('NavBar render');

  const handleSelect = (eventKey) => {
    switch (eventKey) {
      case 'Action-NewItem': {
        onNewItemClick();
        break;
      }
      case 'Action-Export': {
        onDisplayDataEditor();
        break;
      }
      case 'Action-UserGuide': {
        onToggleDisplayGuide();
        break;
      }
      default: { return false; }
    }
    return true;
  };


  return (
    <Navbar collapseOnSelect expand="sm" variant="dark" className="sf-navbar" onSelect={handleSelect}>
      <Navbar.Brand className="sf-navbar-brand">
        <img src="/logoMain1.png" alt="Save Your Fingers logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav variant="dark" className="mr-auto" activeKey="">
          <Nav.Link eventKey="Action-NewItem" className="sf-navbar-createlink">New Item</Nav.Link>
          <Nav.Link eventKey="Action-UserGuide">User Guide</Nav.Link>
          <NavDropdown title="Actions" id="collasible-nav-dropdown">
            <NavDropdown.Item eventKey="Action-NewItem">Create New Item</NavDropdown.Item>
            <NavDropdown.Divider />
            {/* // These menu items are coming soon */}
            {/* <NavDropdown.Item onClick={showComingSoonMessage}>Import Items</NavDropdown.Item> */}
            <NavDropdown.Item eventKey="Action-Export">Export Items</NavDropdown.Item>
            {/* <NavDropdown.Divider /> */}
            {/* <NavDropdown.Item>Clear All</NavDropdown.Item> */}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      <Button type="submit" className="sf-navbar-create" onClick={onNewItemClick}>Create New Item</Button>
      {/* For SEARCH later... */}
      {/* <Form inline>
    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
    <Button variant="outline-info">Search</Button>
    </Form> */}
    </Navbar>
  );
}

NavigationBar.propTypes = {
  onNewItemClick: PropTypes.func.isRequired,
  onDisplayDataEditor: PropTypes.func.isRequired,
  onToggleDisplayGuide: PropTypes.func.isRequired,
};

export default NavigationBar;
