import { useReducer, useEffect } from 'react';
import Logger from './Logger';

function useLocallyPersistedReducer(reducer, defaultState, storageKey, init = null, lastUpdateKey, updateLastUpdateKey) {
  // use a usereducerhook here inside, passing params from above and also an init method (lazy loads the intial state value
  const state = useReducer(reducer, defaultState, (defaultState2) => {
    // this is lazy load init method, gets data
    const rawDatafromStorage = localStorage.getItem(storageKey);
    Logger.LogDebug(`Data from storage: ${rawDatafromStorage}`);
    const dataFromStorage = JSON.parse(rawDatafromStorage);
    if (dataFromStorage !== null ) {
      // data is here in storage and so return
      return dataFromStorage;
    }
    // ok no local storage data, so need to call init if not null else return passed intital state
    return init !== null ? init(defaultState2) : defaultState2;
    //return dataFromStorage !== null ? dataFromStorage : init !== null ? init(defaultState2) : defaultState2;
  });

  // useEffect hook to catch after renders and events happen
  useEffect(() => {
    Logger.LogDebug('saving to storage check');
    // check update key to see if anythings changed
    const stateUpdateKey = state[0].updateKey;
    Logger.LogDebug(`stateUpdateKey = ${stateUpdateKey}`);
    Logger.LogDebug(`lastUpdateKey = ${lastUpdateKey}`);
    if (stateUpdateKey !== lastUpdateKey) {
      if (lastUpdateKey !== '') {
        // somethings different so update local storage
        // skipping this if lastupdatekey is blank as probably first time through
        Logger.LogDebug('Persist data to storage');
        localStorage.setItem(storageKey, JSON.stringify(state[0]));
      }
      // update state somewhere with the latest update key
      updateLastUpdateKey(stateUpdateKey);
    }
  }, [lastUpdateKey, state, storageKey, updateLastUpdateKey]);

  return state;
}

export default useLocallyPersistedReducer;
