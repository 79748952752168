/* eslint-disable no-console */

// switches for now until make them use environment variables
const LOG_DEBUG = true;
const LOG_WARN = true;
const LOG_INFO = true;
const LOG_ERROR = true;

const Logger = {
  LogDebug(data) {
    if (LOG_DEBUG) {
      console.debug(data);
    }
  },
  LogDebugWithJSON(message, json) {
    if (LOG_DEBUG) {
      const fullMsg = `${message} ${JSON.stringify(json)}`;
      this.LogDebug(fullMsg);
    }
  },
  LogError: (data) => {
    if (LOG_ERROR) {
      console.error(data);
    }
  },
  LogWarning: (data) => {
    if (LOG_WARN) {
      console.warn(data);
    }
  },
  LogInfo: (data) => {
    if (LOG_INFO) {
      console.info(data);
    }
  },
};

export default Logger;
