/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-boolean-value */
import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import useLocallyPersistedReducer from './useLocallyPersistedReducer';
import ItemReducer from './ItemReducer';
import NavigationBar from './NavigationBar';
import ItemsContainer from './ItemsContainer';
import ItemDetailModal from './ItemDetailModal';
import DataViewModal from './DataViewModal';
import FooterComponent from './FooterComponent';
import ConfirmActionModal from './ConfirmActionModal';
import Logger from './Logger';
import UserGuide from './UserGuide';
import './App.css';
import emptyState from './InitialDataset.json';

const DATAKEY = 'sf-data-prod1';

function App() {
  // init the state for last update key tracking
  const [lastUpdateKey, setLastUpdateKey] = useState('');
  // state for edit item modal showing or not
  const [showEditModal, setShowEditModal] = useState(false);
  // state for edit item being edited
  const [itemUnderEdit, setItemUnderEdit] = useState(null);
  // state for raw data view
  const [showRawDataModal, setShowRawDataModal] = useState(false);
  // state for confirmation dialog on/off
  const [showConfirmDeleteAction, setShowConfirmDeleteAction] = useState(false);
  // state for user guide display
  const [userGuideOpen, setUserGuideOpen] = useState(false);

  // init the reducer
  const [state, dispatch] = useLocallyPersistedReducer(ItemReducer, emptyState, DATAKEY, null, lastUpdateKey, setLastUpdateKey);

  const getRawDataFromStorage = () => localStorage.getItem(DATAKEY);

  const setConfirmActionDeleteCancel = () => {
    setShowConfirmDeleteAction(false);
  };

  const handleDeleteItem = (itemToDelete) => {
    // check if anything to do
    if (itemToDelete !== null) {
      Logger.LogDebug('handleDeleteItem');
      dispatch({ type: 'delete-item', payload: itemToDelete });
      setShowConfirmDeleteAction(false);
    }
  };

  const handleDeleteItemRequest = (itemToDelete) => {
    // check if anything to do
    if (itemToDelete !== null) {
      Logger.LogDebug('handleDeleteItemRequest');
      setShowConfirmDeleteAction(true);
    }
  };

  const handleDisplayDataEditor = () => {
    setShowRawDataModal(true);
  };

  const handleCloseRawDataView = () => {
    setShowRawDataModal(false);
  };

  const handleShowUserGuide = () => {
    setUserGuideOpen(!userGuideOpen);
  };

  const handleSaveItem = (newItem) => {
    // check if anything to save
    if (newItem !== null) {
      // if there's an ID already its an update, else new item
      if (newItem.id !== null && newItem.id.length > 0) {
        // update item
        Logger.LogDebug('handleSaveItem - its an update');
        dispatch({ type: 'update-item', payload: newItem });
      } else {
        // new item
        Logger.LogDebug('handleSaveItem - its a new item');
        dispatch({ type: 'new-item', payload: newItem });
      }
    }
    // close modal dialog and set local edit item to blank
    setItemUnderEdit(null);
    setShowEditModal(false);
  };

  const handleShowDialog = () => setShowEditModal(true);

  const handleShowDialogForEdit = (itemToEdit) => {
    Logger.LogDebug(`handleShowDialogForEdit called. Item under edit: ${itemToEdit.id}`);
    setItemUnderEdit(itemToEdit);
    setShowEditModal(true);
  };

  let editModalComponent;
  if (showEditModal) {
    editModalComponent = <ItemDetailModal modalOpen={showEditModal} itemDetail={itemUnderEdit} key={itemUnderEdit ? itemUnderEdit.id : 0} onSaveItem={handleSaveItem} />;
  }

  let rawDataViewComponent;
  if (showRawDataModal) {
    rawDataViewComponent = <DataViewModal modalOpen={true} data={getRawDataFromStorage()} onClose={handleCloseRawDataView} />;
  }

  let confirmationDeleteComponent;
  if (showConfirmDeleteAction) {
    confirmationDeleteComponent = (
      <ConfirmActionModal
        modalOpen="true"
        proceedAction={handleDeleteItem}
        cancelAction={setConfirmActionDeleteCancel}
        title="Delete Item"
        message="Are you sure you want to delete this item?"
        cancelButtonText="Cancel"
        okButtonText="Delete"
      />
    );
  }
  return (
    <div className="sf-app">
      <div className="sf-app-container">
        <div className="sf-app-content-wrap">
          <NavigationBar
            onNewItemClick={handleShowDialog}
            onDisplayDataEditor={handleDisplayDataEditor}
            onToggleDisplayGuide={handleShowUserGuide}
          />
          <div className="sf-app-blurbcontainer">
            Always typing out the same text every day? Save your fingers and save it once here and just copy/paste on demand.
            <button type="button" className="btn btn-link sf-app-blurblink" onClick={handleShowUserGuide}>Learn more here</button>
          </div>
          <Collapse in={userGuideOpen}>
            <div>
              <UserGuide toggleDisplayGuide={handleShowUserGuide} createNewItem={handleShowDialog} />
            </div>
          </Collapse>
          <ItemsContainer items={state.items} editItem={handleShowDialogForEdit} deleteItem={handleDeleteItemRequest} />
          {editModalComponent}
          {confirmationDeleteComponent}
          {rawDataViewComponent}
        </div>
        <div className="sf-app-footer">
          <FooterComponent />
        </div>
      </div>
    </div>
  );
}

export default App;
