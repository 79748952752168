import Logger from './Logger';
import CommonFunctions from './CommonFunctions';

function ItemReducer(state, action) {
  switch (action.type) {
    // case 'clear' :
    //   return {word: ''};
    // case 'load':
    //   return
    case 'delete-item': {
      Logger.LogDebug('action delete-item');
      const fatedItem = { ...action.payload };
      Logger.LogDebugWithJSON('fated item', fatedItem);
      const newState = { ...state };
      newState.updateKey = CommonFunctions.GenerateRandomNumber();
      Logger.LogDebug(`state items count is ${newState.items.length}`);

      // find the place in array for existing item we are updating
      const existingItemIndex = newState.items.findIndex((x) => x.id === fatedItem.id);
      // and remove it
      newState.items.splice(existingItemIndex, 1);

      Logger.LogDebug(`state items count is now ${newState.items.length}`);
      return newState;
    }
    case 'update-item': {
      Logger.LogDebug('action update-item');
      const updatedItem = { ...action.payload };
      Logger.LogDebugWithJSON('updated item', updatedItem);
      // TODO validate item here
      const newState = { ...state };
      newState.updateKey = CommonFunctions.GenerateRandomNumber();

      // find the place in array for existing item we are updating
      const existingItemIndex = newState.items.findIndex((x) => x.id === updatedItem.id);
      // and update it over the top (splat)
      newState.items[existingItemIndex] = updatedItem;

      Logger.LogDebug(`state items count is now ${newState.items.length}`);
      return newState;
    }
    case 'new-item': {
      Logger.LogDebug('action new-item');
      const newItem = { ...action.payload };
      // new item needs a new id
      newItem.id = CommonFunctions.GenerateUniqueID();
      Logger.LogDebugWithJSON('new item', newItem);
      // TODO validate item here
      const newState = { ...state };
      newState.updateKey = CommonFunctions.GenerateRandomNumber();
      Logger.LogDebug(`state items count is ${newState.items.length}`);
      newState.items.push(newItem);
      Logger.LogDebug(`state items count is now ${newState.items.length}`);
      return newState;
    }
    default:
      return state;
  }
}

export default ItemReducer;
