/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Collapse, Button } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './ItemComponent.css';

function ItemComponent(props) {
  const { item, editItem, deleteItem } = props;
  const [open, setOpen] = useState(false);
  const [animateOn, setAnimateOn] = useState(''); // holds the animation class to trigger
  const buildShortDescription = (data) => (data.length > 40 ? `${data.substring(0, 40)}...` : data);

  // fires once the animation has completed, so we can reset the class in state
  const onAnimationEnd = () => {
    setAnimateOn('');
  };

  const prepDataForDisplay = (rawData) => ({ __html: rawData.replace(/[\n\r]/g, '<br/>') });

  const toggleCardDetail = (show) => {
    // if shortdata shows all detail no need to open so just return
    if (item.data.length > 0 && item.data.length < 40) return;
    // ok, something to show or hide, so toggle open
    setOpen(show);
  };

  return (
    <>
      <Card
        className={`border-secondary sf-item-card ${animateOn}`}
        onAnimationEnd={onAnimationEnd}
      >
        <Card.Body className="sf-item-card-body">
          <Card.Title className="sf-item-card-title">
            <div className="sf-item-title-container">
              <div className="sf-item-title-container-text">{item.title}</div>
              <div className="sf-item-title-container-btnspace">
                <CopyToClipboard
                  text={item.data}
                  onCopy={() => setAnimateOn('sf-item-action-animation')}
                >
                  <Button variant="outline-primary" size="sm">
                    Copy
                  </Button>
                </CopyToClipboard>
                {' '}
              </div>
            </div>
          </Card.Title>
          <Card.Subtitle className="mb-2 sf-item-card-subtitle">
            <div
              onClick={() => toggleCardDetail(!open)}
              onKeyPress={() => toggleCardDetail(!open)}
              role="button"
              tabIndex={0}
            >
              {open ? '' : buildShortDescription(item.data)}
            </div>
          </Card.Subtitle>
          <Collapse in={open}>
            <Card.Text
              dangerouslySetInnerHTML={prepDataForDisplay(item.data)}
            >
            </Card.Text>
          </Collapse>
          <Button
            variant="link"
            size="sm"
            onClick={() => toggleCardDetail(!open)}
            className="sf-item-card-link"
          >
            { open ? 'Hide Detail' : 'Show Detail'}
          </Button>
          <Button
            variant="link"
            size="sm"
            onClick={() => editItem(item)}
            className="sf-item-card-link"
          >
            Edit
          </Button>
          <Button
            variant="link"
            size="sm"
            onClick={() => deleteItem(item)}
            className="sf-item-card-link"
          >
            Delete
          </Button>
        </Card.Body>
      </Card>
    </>
  );
}

ItemComponent.propTypes = {
  item: PropTypes.object.isRequired,
  editItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
};

export default ItemComponent;
