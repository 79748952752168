import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './DataViewModal.css';

function DataViewModal(props) {
  const { modalOpen, data, onClose } = props;

  return (
    <>
      <Modal show={modalOpen} className="sf-dataview-modal" onHide={onClose} backdrop="static" dialogClassName="sf-dataview-modaldlg">
        <Modal.Header closeButton>
          <Modal.Title className="sf-dataview-modal-foot">Raw Data</Modal.Title>
        </Modal.Header>
        <Modal.Body className="sf-dataview-modal-bod">
          <p>Raw data displayed below for information or copying for backup.</p>
          <div className="sf-dataview-modal-textdiv" contentEditable="true" suppressContentEditableWarning>{data}</div>
        </Modal.Body>
        <Modal.Footer className="sf-dataview-modal-foot">
          <Button variant="secondary" onClick={onClose}>
              Close
          </Button>
          {/* <Button variant="primary" onClick={handleSave}>
              Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

DataViewModal.defaultProps = {
  data: '',
};

DataViewModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.string,
};

export default DataViewModal;
