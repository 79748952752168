import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Logger from './Logger';
import './ItemDetailModal.css';

function ItemDetailModal(props) {
  const { modalOpen, itemDetail, onSaveItem } = props;
  const [name, setName] = useState('');
  const [data, setData] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const clearState = () => {
    setName('');
    setData('');
    setIsEdit(false);
  };

  // useEffect hook to catch after renders and events happen
  useEffect(() => {
    if (itemDetail !== null) {
      Logger.LogDebugWithJSON('ItemDetailModal init : itemDetail is', JSON.stringify(itemDetail.title));
      setName(itemDetail.title);
      setData(itemDetail.data);
      setIsEdit(true);
    } else {
      Logger.LogDebug('ItemDetailModal init : itemDetail is null');
      setIsEdit(false);
      clearState();
    }
  }, [itemDetail]);

  const handleClose = () => {
    Logger.LogDebug('handleClose');
    clearState();
    onSaveItem(null);
  };
  const handleSave = () => {
    Logger.LogDebug('handleSave');
    let itemToSave;
    if (itemDetail === null) {
      Logger.LogDebug('handleSave - new item');
      itemToSave = { title: name, data, id: '' };
    } else {
      Logger.LogDebug('handleSave - existing item');
      itemToSave = itemDetail;
      itemToSave.title = name;
      itemToSave.data = data;
    }
    clearState();
    onSaveItem(itemToSave);
  };

  const dialogTitle = isEdit ? 'Edit Item' : 'New Item';

  return (
    <>
      <Modal show={modalOpen} className="sf-item-modal" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="sf-item-modal-foot">{dialogTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="sf-item-modal-bod">
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Friendly Name:</Form.Label>
              <Form.Control type="text" placeholder="" defaultValue={name} onChange={(e) => setName(e.target.value)} />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Text To Send To Clipboard:</Form.Label>
              <Form.Control as="textarea" rows="3" defaultValue={data} onChange={(e) => setData(e.target.value)} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="sf-item-modal-foot">
          <Button variant="secondary" onClick={handleClose}>
              Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
              Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ItemDetailModal.defaultProps = {
  itemDetail: null,
};

ItemDetailModal.propTypes = {
  onSaveItem: PropTypes.func.isRequired,
  itemDetail: PropTypes.object,
  modalOpen: PropTypes.bool.isRequired,
};

export default ItemDetailModal;
